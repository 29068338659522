











import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { EditorContent } from 'tiptap';

export default Vue.extend({
	name: 'Journal',
	components: {
		EditorContent
	},
	computed: mapGetters(['getSelectedJournal', 'getEditor']),
	methods: mapActions(['createJournalEditor', 'destroyEditor']),
	beforeMount() {
		this.createJournalEditor();
	},
	beforeDestroy() {
		this.destroyEditor();
	}
});
